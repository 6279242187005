import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Loadable from './components/Loadable';
import Layout from './components/layout';
import OTPVerification from './components/sessions/OTPVerification';

// session pages
const NotFound = Loadable(lazy(() => import('./components/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('./components/sessions/JwtLogin')));
const ImpersonateLogin = Loadable(lazy(() => import('./components/sessions/ImpersonateLogin')));
const JwtRegister = Loadable(lazy(() => import('./components/sessions/JwtRegister')));
const ForgotPassword = Loadable(lazy(() => import('./components/sessions/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('./components/sessions/ResetPassword')));
const Home = Loadable(lazy(() => import('./components/Home')));
const WelcomPage = Loadable(lazy(() => import('./components/sessions/WelcomPage')));

// dashboard page
export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <Layout />,
      children: [
        { path: '/signin', element: <JwtLogin /> },
        { path: '/home', element: <Home /> },
        { path: '/signup', element: <JwtRegister /> },
        { path: '/forgot-password', element: <ForgotPassword /> },
        { path: '/reset-password', element: <ResetPassword /> },
        { path: '/otp-verification', element: <OTPVerification /> },
        { path: '/impersonate_login', element: <ImpersonateLogin /> },
        { path: '/welcom', element: <WelcomPage /> }
      ]
    },

    { path: '/404', element: <NotFound /> },
    { path: '/', element: <Navigate to="/signin" />, index: true },
    { path: '*', element: <NotFound /> }
  ]);
  return routes;
}
