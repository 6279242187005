import React from 'react';
import Grid from '@mui/material/Grid';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <div style={{ background: '#1A2038', height: '100vh' }}>
      <Grid container>
        <Grid item xs={12} md={6} m={3}>
          <img src="/assets/images/ob-logo.png" width="200px" height="auto" alt="logo" />
        </Grid>
        <Grid item xs={12}>
          <Outlet />
        </Grid>
      </Grid>
    </div>
  );
};

export default Layout;
