import { Alert, Box, Button, Card, Snackbar, Stack, Typography, styled } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// import { setSnackBarAlert } from '../../reducers/slices/AlertsSlice';
// import { useDispatch } from 'react-redux';
// import { get } from 'lodash';
// import { errorMessageHandler } from '../../utils/helpers';
// import axios from '../../utils/axios';
import { LoadingButton } from '@mui/lab';
import OtpInput from 'react-otp-input';
import { useEffect } from 'react';
import { useState } from 'react';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: 'center'
}));

const ContentBox = styled(Box)(({ theme }) => ({
  padding: '10px 30px 20px',
  background: theme?.palette?.background?.default
}));

const OTPVerificationRoot = styled(JustifyBox)(() => ({
  background: '#1A2038',
  '& .card': {
    margin: '4rem',
    borderRadius: 12,
    width: '90%',
    '@media (min-width: 900px)': {
      width: '30%'
    }
  }
}));

const OTPVerification = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const location = useLocation();
  const [otpValue, setOtpValue] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const otpFromState = location.state?.otp;
    if (otpFromState) {
      setOtpValue(otpFromState);
    }
    setOpenSnackbar(true);
  }, [location.state]);

  const initialValues = {
    otp: ''
  };
  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .required('One-time password is required')
      .matches(/^\d{4}$/, 'OTP must be exactly 4 digits')
  });

  const verifyOTP = async (param) => {
    navigate('/');
    // const { values, resetForm, setErrors, setSubmitting } = param;
    // try {
    //   const response = await axios.post('/api/reset_password', {
    //     otp: values.otp
    //   });
    //   const responseData = response.data;
    //   if (get(responseData, 'status', false)) {
    //     resetForm();
    //     navigate('/');
    //   } else {
    //     dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(responseData) }));
    //     setErrors({
    //       submit: errorMessageHandler(responseData)
    //     });
    //   }
    // } catch (error) {
    //   dispatch(setSnackBarAlert({ severity: 'error', message: 'Something went wrong!' }));
    //   setErrors({
    //     submit: ['Something went wrong!']
    //   });
    // } finally {
    //   setSubmitting(false);
    // }
  };

  const handleFormSubmit = (values, { resetForm, setErrors, setSubmitting }) => {
    verifyOTP({
      values,
      resetForm,
      setErrors,
      setSubmitting
    });
  };

  return (
    <>
      <OTPVerificationRoot>
        <Card className="card">
          <JustifyBox p={4}>
            <img width="300" src="/assets/images/illustrations/dreamer.svg" alt="" />
          </JustifyBox>

          <ContentBox>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleFormSubmit}>
              {({ values, setFieldValue, isSubmitting }) => (
                <Form>
                  <Stack spacing={2}>
                    <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
                      <Typography variant="h6">Enter OTP</Typography>
                      <OtpInput
                        value={values.otp}
                        onChange={(otp) => setFieldValue('otp', otp)}
                        numInputs={4}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => (
                          <input
                            {...props}
                            type="text"
                            maxLength="1"
                            style={{
                              width: '2em',
                              height: '2em',
                              fontSize: '30px',
                              margin: '0 0.25em',
                              textAlign: 'center'
                            }}
                          />
                        )}
                      />

                      <ErrorMessage name="otp" component="div" style={{ color: 'red' }} />
                    </Stack>
                    <LoadingButton fullWidth type="submit" color="primary" loading={isSubmitting} variant="contained" sx={{ my: 2 }}>
                      Verify OTP
                    </LoadingButton>
                    <Button fullWidth color="primary" variant="outlined" onClick={() => navigate(-1)} sx={{ mt: 2 }}>
                      Go Back
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </ContentBox>
        </Card>
      </OTPVerificationRoot>
      <Snackbar open={openSnackbar}>
        <Alert elevation={6} variant="filled" onClose={() => setOpenSnackbar(false)} severity={otpValue ? 'info' : 'error'}>
          {otpValue ? (
            <Typography>
              OTP Value: <strong>{otpValue}</strong>
            </Typography>
          ) : (
            <Typography>Something went wrong!</Typography>
          )}
        </Alert>
      </Snackbar>
    </>
  );
};

export default OTPVerification;
